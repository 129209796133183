module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"308587290","head":true,"respectDNT":true,"pageTransitionDelay":0,"optimizeId":"GTM-PDL69FM","defer":false,"cookieDomain":"https://www.dimlab.app/","enableWebVitalsTracking":true,"anonymize":false,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-1JMKSRLY9R"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dimension Lab","short_name":"Dimension Lab","start_url":"/","display":"minimal-ui","background_color":"#00195C","theme_color":"#00195C","theme_color_in_head":true,"icon":"src/images/lightLogo.svg","legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"dcf20c0b6105d491006edff1112baa09"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
